export default {

  light: {
    background: "#F2F3F4",

    // Overall
    primary: "#0089FF",
    "primary-medium": "#0033A1",
    "primary-dark": "#0033A1",
    secondary: "#F86700",

    "off-black": "#3c4b6c",
    "light-grey": "#F5F5F8",
    yellow: "#FFBF00",
    purple: "#3924A8",
    blue: "#5A40FB",
    green: "#4CAF50",

    // Text
    text: "#5c6c80",
    "medium-text": "#3C4B6C",
    "dark-text": "#0A2240",

    // Buttons
    "green-button": "#0089FF", // Std bank green buttons are primary blue
    "primary-button": "#0089FF",
    "primary-button-text": "#FFFFFF",
    "secondary-button": "#0089FF",
    "secondary-button-text": "#FFFFFF",

    // Section Nav Bar
    "section-nav-background": "#FFFFFF",
    "section-nav-divider-color": "#4E4B66",
    "section-nav-inactive-link-background": "#98bbda",
    "section-nav-inactive-link-text-text": "#FFFFFF",
    "section-nav-active-background": "#eaf6ff",
    "section-nav-active-text": "#0089FF",

    // Cards
    "card-title": "#858D9D",

    // App header
    "app-header-bar": "#0033A1",
    "app-header-bar-text": "#FFFFFF",

    // Breadcrumbs
    breadcrumb: "#0A2240",
    "breadcrumb-link": "#FFFFFF",
    "breadcrumb-text": "#FFFFFF",
    "breadcrumb-separator": "#FFFFFF",

    // Secondary Navigation
    "secondary-nav": "#0033A1",
    "secondary-nav-content": "#FFF",

    // Spinner
    "spinner-overlay": "#ffffff",

    // Gradients
    "primary-gradient-start": "#1F59D8",
    "primary-gradient-end": "#0033A1",
    "light-gradient-start": "#00C2FF",
    "light-gradient-end": "#0030DC",
    "secondary-gradient-start": "#FF5A00",
    "secondary-gradient-end": "#FFB94F",
    "mid-gradient-start": "#1F59D8",
    "mid-gradient-end": "#0033A1",
    "header-gradient-start": "#0433a1",
    "header-gradient-end": "#0433a1",
  },
};
