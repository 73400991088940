import environmentConfig from "@/config/environment.config";

(function(t, d, k) { (t[k] = t[k] || []).push(d); t[d] = t[d] || t[k].f || function() { (t[d].q = t[d].q || []).push(arguments); }; })(window, "trackdesk", "TrackdeskObject");

function trackClick() {
  if (environmentConfig.trackDeskId && typeof trackdesk !== "undefined")
    // eslint-disable-next-line no-undef
    trackdesk(environmentConfig.trackDeskId, "click");
}

function finishedWorkerTypeAssessment(userId) {
  if (environmentConfig.trackDeskId && typeof trackdesk !== "undefined")
    // eslint-disable-next-line no-undef
    trackdesk(environmentConfig.trackDeskId, "conversion", {
      conversionType: "lead",
      customerId: userId,
    });
}

export default {
  trackClick,
  finishedWorkerTypeAssessment
};
