<template>
  <div v-cloak id="app">
    <!-- To get sticky app bar to work -->
    <meta
      name="viewport"
      content="height=device-height,
                      width=device-width, initial-scale=1.0,
                      minimum-scale=1.0, maximum-scale=1.0,
                      user-scalable=no"
    >

    <v-app v-if="!isAppLoaded || fatalError || !appConfiguration">
      <FatalErrorCard v-if="fatalError" :fatal-error="fatalError" />
    </v-app>
    <v-app v-else>
      <router-view />
    </v-app>
  </div>
</template>

<script>
import store from "./store";
import i18n from "./i18n";
import router from "./router/router";
import { loadDynamicScripts } from "@/library/dynamicScripts";
import { setSignupCookies } from "@/helpers/RouteAccessChecksHelper";
import FatalErrorCard from "@/components/common/FatalErrorCard.vue";

export default {
  name: "App",
  components: {FatalErrorCard },
  store,
  i18n,
  router,
  data() {
    return {
      app_url: process.env.VUE_APP_URL,
      loadingRoute: false,
    };
  },
  computed: {
    /**
     * Triggers the loading spinner prior to app loading completing
     * @returns {any}
     */
    isAppLoaded() {
      return store.state.appLoaded;
    },
    fatalError() {
      return store.state.fatalError;
    },
  },

  async created() {
    // Loads any dynamic scripts that need to be included in the app
    // Example: OneTrust Cookie Banner
    loadDynamicScripts();

    // Set up signup cookies
    this.setSignupCookies();
  },
  async mounted() {
    // Get the app configuration
    await store.dispatch("updateAppConfiguration");

    // Used to track re-engagement
    this.$analyticsEvents.openedApp();

    // Set language
    this.setLanguage();
  },
  metaInfo: {
    title: process.env.VUE_APP_TITLE,
  },
  methods: {
    setLanguage() {
      const storedLanguage = localStorage.getItem("language");

      if (storedLanguage) this.$root.$i18n.locale = storedLanguage;
      else if (this.appConfiguration.locales.default) {
        this.$root.$i18n.locale = this.appConfiguration.locales.default;
        localStorage.setItem("language", this.appConfiguration.locales.default);
      } else {
        this.$root.$i18n.locale = "en";
        localStorage.setItem("language", "en");
      }
    },
    setSignupCookies() {
      setSignupCookies();
    },
  },
};
</script>

<style lang="scss">
@import "./assets/styles/_global.scss";
@import "./scss/theme-styles/_global.scss";
</style>
