import { render, staticRenderFns } from "./AuthenticationPageLayout.vue?vue&type=template&id=638863a2&scoped=true"
import script from "./AuthenticationPageLayout.vue?vue&type=script&lang=js"
export * from "./AuthenticationPageLayout.vue?vue&type=script&lang=js"
import style0 from "./AuthenticationPageLayout.vue?vue&type=style&index=0&id=638863a2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.4.27_babel-core@7.0.0-bridge.0_@babel+core@7.24.7__css_odwimgpdnzofzabuomrm66ynwa/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "638863a2",
  null
  
)

export default component.exports