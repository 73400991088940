import mixpanel from "mixpanel-browser";
import Vue from "vue";
import store from "@/store";
import environmentConfig from "@/config/environment.config";

// Checks the device type of the user
const deviceCheck = /Mobile/i.test(navigator.userAgent) ? "Mobile" : "Desktop";
const isMixPanelEnabled = !!process.env.VUE_APP_MIXPANEL_ID;

function updateDataLayer(data) {
  if (environmentConfig.adobeAnalyticsTracking)
    window.dataLayer = data;
}

/**
 * Sends an event to Adobe Analytics
 * @param eventProperties The properties to be added to the data layer
 * @param type The type of event that is tracked
 */
function sendAdobeAnalyticsEvent(eventProperties = {}, type) {
  /* eslint-disable no-undef */

  // Only track events where the correct type is provided
  if (
    type !== "linkClick" &&
    type !== "pageView" &&
    type !== "formStart" &&
    type !== "formComplete"
  )
    return;

  // Direct Link Click Events
  if (type === "linkClick") {
    if (!eventProperties.intent || !eventProperties.name || !eventProperties.scope)
      throw new Error("Incorrect event properties for flexiLinkTracking event");

    updateDataLayer({
      linkIntent: eventProperties.intent,
      linkName: eventProperties.name,
      linkScope: eventProperties.scope
    });
    _satellite.track("flexiLinkTracking");
  }

  // Page View Events
  if (type === "pageView") {
    if (!eventProperties.pageName)
      throw new Error("Incorrect event properties for globalVirtualPageView event");

    const data = {
      deviceType: deviceCheck,
      siteCountry: "South Africa",
      siteErrorCode: "",
      siteLanguage: "English",
      pageCategory: "Personal",
      pageName: eventProperties.pageName,
      ...eventProperties.pageSubSections,
      websiteName: "PluggedIn Platform",
      websiteNameCode: "PPF"
    };

    // Get GUID and EmailSha for tracking
    const user = store.getters.getUser;

    if (user) {
      const userId = user._id;
      if (userId) data.customerGuid = userId;
    }

    updateDataLayer(data);
    _satellite.track("globalVirtualPageView");

    // Track the users authentication
    // IF THE USER AUTH HAS NOT BEEN TRACKED YET AND THIS IS THEIR FIRST PAGE VIEW AFTER AUTH
    // Only track when auth method is oAuth (not session check) and the page is not the oAuth redirect page
    const isSuccessfulAuthTracked = store.getters.isSuccessfulAuthTracked;
    const authenticationMethod = store.getters.getAuthenticationMethod;

    if (
      user &&
      !isSuccessfulAuthTracked &&
      authenticationMethod === "oAuth" &&
      eventProperties.pageName !== "auth:oauth success"
    ) {
      const emailSHA256 = store.getters.getUserEmailSHA256;

      // Add auth tracking data to datalayer
      const authData = {
        ...data,
        formName: "",
        formStatus: "",
        formisSubmitted: false,
        loginStatus: "logged-in",
        siteBusinessUnit: "CHNW",
        email_sha256: emailSHA256
      };
      updateDataLayer(authData);

      // Update user auth store to track that auth event has been sent
      store.dispatch("updateSuccessfulAuthTracking");
      _satellite.track("globalAuthenticationComplete");
    }
  }

  // Form Start Events
  if (type === "formStart") {
    if (!eventProperties.name)
      throw new Error("Incorrect event properties for globalFormStart event");

    updateDataLayer({
      formName: eventProperties.name,
      formIsSubmitted: false,
      formStatus: ""
    });
    _satellite.track("globalFormStart");
  }

  // Form Complete Events
  if (type === "formComplete") {
    if (!eventProperties.name)
      throw new Error("Incorrect event properties for globalFormComplete event");

    updateDataLayer({
      formName: eventProperties.name,
      formIsSubmitted: true,
      formStatus: "complete"
    });
    _satellite.track("globalFormComplete");
  }
}

/**
 * Tracks an event in MixPanel
 * @param eventName The name of the event in Mix Panel
 * @param eventProperties The properties to be stored with the event
 */
function trackMixpanelEvent(eventName, eventProperties) {
  mixpanel.track(eventName, eventProperties);
}

/**
 * Sends an event to Google Analytics, using GTag
 * @param eventName The name of the event
 * @param eventProperties The properties to be stored with the event
 * @param eventCategory The category of the event, eg auth, explorers
 * @param eventId The ID to be used for the event
 */
function trackGTagEvent(eventName, eventProperties, eventCategory, eventId) {
  Vue.prototype.$gtag.event(eventId, {
    event_category: eventCategory,
    event_label: eventName,
    ...eventProperties
  });
}

/**
 * Sends up an event to the analytics tracking tool
 * @param eventName
 * @param eventProperties
 */
function sendEvent(eventName, eventProperties = {}, eventCategory, eventId, type) {
  // Track event in adobe analytics
  /* eslint-disable no-undef */
  if (typeof _satellite !== "undefined") sendAdobeAnalyticsEvent(eventProperties, type);

  // Track event in mixpanel
  if (type !== "linkClick" && isMixPanelEnabled) trackMixpanelEvent(eventName, eventProperties);

  // Track event in GTag
  if (Vue.prototype.$gtag) trackGTagEvent(eventName, eventProperties, eventCategory, eventId);
}

/**
 * Resets all identifier information in tracking events
 */
function reset() {
  if (isMixPanelEnabled)
    mixpanel.reset();
}

/**
 * Sets the user alias in Mix Panel, associating them with the Distinct ID
 * Called AFTER REGISTRATION so as to link events from before registration, to those after
 * See:https://help.mixpanel.com/hc/en-us/articles/115004497803-Identity-Management-Best-Practices
 * @param username The users email or cell phone number
 */
function alias(username) {
  if (isMixPanelEnabled)
    mixpanel.alias(username);
}

/**
 * Associates an existing user to events Mix Panel
 * Called AFTER LOGIN
 * See:https://help.mixpanel.com/hc/en-us/articles/115004497803-Identity-Management-Best-Practices
 * @param email
 */
function identify(email) {
  if (isMixPanelEnabled)
    mixpanel.identify(email);
}

/**
 * Records the time between two events (eg Page Exited)
 * See: https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpaneltime_event
 * @param event The name of the Mix Panel event
 */
function timeEvent(event) {
  if (isMixPanelEnabled)
    mixpanel.time_event(event);
}

/**
 * Updates the data layers and MixPanel properties
 * @param user The authenticated user object
 */
function setAuthenticatedUserPropertiesForAnalytics(user) {
  // Update the Adobe Analytics Data Layer
  updateDataLayer({
    loginStatus: "logged in",
    customerGuid: user._id,
    email_sha256: user.emailSHA256
  });

  // Update the Mix Panel super properties
  setMixPanelUserProperties(user);
}

/**
 * Set Mix Panels user properties, this is done after Super Properties have been set
 * See: https://help.mixpanel.com/hc/en-us/articles/360001355266-Event-Properties
 * @param user
 */
function setMixPanelUserProperties(user) {
  const properties = {
    planType: user.app.accountType,
    $email: user.email,
    phone: user.phone,
    $firstName: user.profile.firstName
  };

  // Adds the tenant information to MixPanel tracking
  if (user.tenant && user.tenant.institution) properties.institution = user.tenant.institution;

  if (user.tenant && user.tenant.cohort) properties.cohort = user.tenant.cohort;

  if (isMixPanelEnabled)
    mixpanel.register(properties);
}

export default {
  sendEvent,
  updateDataLayer,
  identify,
  timeEvent,
  alias,
  reset,
  setAuthenticatedUserPropertiesForAnalytics,
  setMixPanelUserProperties,
};
