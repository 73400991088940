import LoadScript from "vue-plugin-load-script";
import VueConfetti from "vue-confetti";
import VueMeta from "vue-meta";
import BlockContent from "sanity-blocks-vue-component";
import VueSanitize from "vue-sanitize";
import * as Sentry from "@sentry/vue";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";
import { BrowserTracing } from "@sentry/tracing";
import VueGtag from "vue-gtag";
import Hotjar from "vue-hotjar";
import mixpanel from "mixpanel-browser";
import CrispChat from "@dansmaculotte/vue-crisp-chat";
import analyticsLibrary from "@/library/analytics.lib";
import analyticsEvents from "@/library/analyticsEvents.lib";
import affiliateTracker from "@/library/affiliateTracking.lib";
import VueGtm from "@gtm-support/vue2-gtm";
import router from "@/router/router";

// Current App Version
const VERSION = require("../../package.json").version;
import environmentConfig from "../config/environment.config";
/**
 * Vue configuration
 * @param Vue
 */
function config(Vue) {
  Vue.config.productionTip = false;
  Vue.config.logLevel = "debug";
  Vue.config.errorHandler = (err, vm, info) => {
    // eslint-disable-next-line no-console
    console.error(err);
    // eslint-disable-next-line no-console
    console.error(vm);
    // eslint-disable-next-line no-console
    console.error(info);
  };
  Vue.config.proxyTable = {
    // proxy all requests starting with /api to json placeholder
    "/api": {
      target: "http://localhost:3000",
      changeOrigin: true,
      pathRewrite: {
        "^/api": ""
      }
    }
  };
}

/**
 * Tools for the app
 * @param Vue
 */
function tools(Vue) {
  // Tool to inject external scripts into Vue
  Vue.use(LoadScript);

  // Adds a sanitiser for WYSIWYG content
  const sanitiserOptions = {
    allowedTags: ["b", "p", "strong", "i", "u", "s", "ul", "li", "ol"]
  };
  Vue.use(VueSanitize, sanitiserOptions);

  Vue.prototype.$setLoadingBreadcrumb = function(route) {
    if (!route.meta.breadcrumbs)
      route.meta.breadcrumbs[0] = "...";
    else route.meta.breadcrumbs[route.meta.breadcrumbs.length - 1].title = "...";
  };

  Vue.prototype.$setLastBreadcrumb = function(route, title) {
    if (!route.meta.breadcrumbs)
      route.meta.breadcrumbs[0] = title;
    else route.meta.breadcrumbs[route.meta.breadcrumbs.length - 1].title = title;
  };
}

/**
 * Vue Plugins
 * @param Vue
 */
function plugins(Vue) {
  // Confetti for the assessment success pages
  Vue.use(VueConfetti);
  // Adds meta data
  Vue.use(VueMeta);
  // Add the Sanity Block-Content component
  Vue.component("BlockContent", BlockContent);
}

/**
 * Logging tools for audit and error monitoring
 * @param Vue
 */
function logging(Vue) {
  if (
    process.env.VUE_APP_SENTRY_DSN &&
    (process.env.VUE_APP_SENTRY_ENVIRONMENT === "production" ||
      process.env.VUE_APP_SENTRY_ENVIRONMENT === "staging")
  )
    Sentry.init({
      Vue,
      dsn: process.env.VUE_APP_SENTRY_DSN,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: [process.env.VUE_APP_CV_SERVER_URL],
        }),
        new CaptureConsoleIntegration(
          {
            levels: ["error"]
          }
        ),
        new Sentry.Replay()
      ],
      tracesSampleRate: 0.1,
      environment: `${process.env.VUE_APP_WHITELABEL_CONFIG}-${process.env.VUE_APP_SENTRY_ENVIRONMENT}`,
      release: "yenza-webapp@" + VERSION,
      replaysSessionSampleRate: 0.01,
      replaysOnErrorSampleRate: 0.1
    });
}

/**
 * Tracks affiliate link clicks and conversions
 */
function affiliates(Vue) {
  // Only load if there is a track desk ID defined in tenant config
  if (environmentConfig.trackDeskId) {
    // Allows for global access of the affiliate tracker
    Vue.prototype.$affiliateTracker = affiliateTracker;

    try {
      Vue.loadScript("https://cdn.trackdesk.com/tracking.js");
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }
}

/**
 * Analytics and tracking libraries
 * @param Vue
 * @returns {Promise<void>}
 */
async function analytics(Vue) {
  // Checks the device type of the user
  const deviceCheck = /Mobile/i.test(navigator.userAgent) ? "Mobile" : "Desktop";

  // Creates a global instance of the analytics and events libraries
  Vue.prototype.$analytics = analyticsLibrary;
  Vue.prototype.$analyticsEvents = analyticsEvents;

  // Load Hotjar, if its available in this environment
  if (process.env.VUE_APP_HOTJAR_ID)
    Vue.use(Hotjar, {
      id: process.env.VUE_APP_HOTJAR_ID
    });

  // Load Google Analytics, if it's available in this environment
  if (process.env.VUE_APP_GA_CODE)
    Vue.use(
      VueGtag,
      {
        globalDataLayerName: "gTagDataLayer",
        config: { id: process.env.VUE_APP_GA_CODE }
      },
      router
    );

  if (process.env.VUE_APP_GTM_CODE) {
    window.dataLayer = [];

    Vue.use(VueGtm, {
      id: process.env.VUE_APP_GTM_CODE,
      defer: false,
      enabled: true,
      debug: false,
      loadScript: true,
      vueRouter: router,
      trackOnNextTick: false,
    });
  }

  // Load Mixpanel, if it's available in this environment
  if (process.env.VUE_APP_MIXPANEL_ID) mixpanel.init(process.env.VUE_APP_MIXPANEL_ID);

  // Create the global data layer that will be used by the analytics scripts

  // Load Adobe Analytics, if it's available in this environment
  if (environmentConfig.adobeAnalyticsTracking) {
    window.dataLayer = {
      loginStatus: "logged out",
      userLoginSuccess: false,
      userRegistrationSuccess: false,
      deviceType: `${deviceCheck}`,
      formIsSubmitted: false,
      formName: "",
      formStatus: "",
      websiteName: `${this.$appName} Platform`,
      websiteNameCode: "PPF",
      siteLanguage: "English",
      pageCategory: "Personal",
      pageName: "loading",
      pageSubSection1: "",
      pageSubSection2: "",
      pageSubSection3: "",
      pageSubSection4: "",

      customerGuid: "",
      email_sha256: ""
    };

    try {
      await Vue.loadScript(environmentConfig.adobeAnalyticsTracking);
    } catch (e) {
      return e;
    }
  }
}

/**
 * Whitelabel Configurations
 * @param Vue
 */
function whiteLabel(Vue) {
  // White Label Configuration
  Vue.prototype.$appName = environmentConfig.appName;
  Vue.prototype.$appVersion = VERSION;
  Vue.prototype.$supportEmail = environmentConfig.supportEmail;
}

/**
 * Customer support tools
 * @param Vue
 */
function customerSupport(Vue) {
  if (process.env.VUE_APP_CRISP_CHAT_ID)
    Vue.use(CrispChat, {
      websiteId: process.env.VUE_APP_CRISP_CHAT_ID,
      hideOnLoad: true
    });
}

export default {
  config,
  tools,
  plugins,
  logging,
  analytics,
  affiliates,
  whiteLabel,
  customerSupport
};
