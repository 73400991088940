/**
 * Authentication Module
 */
import router from "@/router/router";

import api from "@/library/api.lib";
import analytics from "@/library/analytics.lib";
import appConfig from "@/config/app.config";

const getDefaultState = () => ({
  successfulAuthTracked: false,
});

export default {
  state: getDefaultState,
  actions: {
    /**
     * Sets up the app with a user object received from the server
     *
     * @param user The user object from the database
     * @returns {Promise<void>}
     */
    async setupUser({ dispatch }, { user }) {
      // Update the global store
      await dispatch("setUserGlobalState", user);

      // TRACK: Set the users identity for analytics
      if (user.email) analytics.identify(user.email);
      else if (user.phone) analytics.identify(user.phone);

      // TRACK: Set their Super Properties
      analytics.setAuthenticatedUserPropertiesForAnalytics(user);
    },
    /**
     * Log the user out of the app
     * @param commit
     */
    async logoutUser({ dispatch, commit, getters }) {
      // Put the app into a loading state to prevent state errors
      dispatch("setAppLoaded", false);

      const authenticationMethod = getters.getAuthenticationMethod;

      // Destroy the user session on the server
      try {
        await api().get("auth/logout");
      } catch (e) {
        throw new Error(e);
      }

      // Reset the global state
      await dispatch("resetGlobalState");
      // Track: remove user identity from analytics tracking
      analytics.reset();

      // Remove the token from the state
      commit("RESET_STATE");

      // Handle SLO redirects for oAuth methods - stdbank and azure-ad
      if (authenticationMethod === "stdbank" || authenticationMethod === "azure-ad")
        return window.location = appConfig.auth[authenticationMethod].singleSignOutUrl;
      else {
        if (router.currentRoute.path !== "/home")
          await router.push("/home");

        dispatch("setAppLoaded", true);
      }
    },

    /**
     * Monitors
     * @param commit
     */
    updateSuccessfulAuthTracking({ commit }) {
      commit("SET_LOGIN_TRACKING_SUCCESS");
    },
  },
  getters: {
    isSuccessfulAuthTracked: (state) => state.successfulAuthTracked,
  },
  mutations: {
    SET_LOGIN_TRACKING_SUCCESS(state) {
      state.successfulAuthTracked = true;
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState());
    }
  }
};
