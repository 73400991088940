import { Feature } from "@YenzaCT/sdk";
import store from "@/store";

export default [
  /** ====================================================================================================
     * Skills Explorer
     ====================================================================================================*/
  {
    path: "/navigators/skillsets/courses",
    component: () =>
      import(
        /* webpackChunkName: "explorers" */ "@/modules/SkillsetsNavigator/pages/SkillsetsNavigatorCoursesListingPage/SkillsNavigatorCoursesListingPage.vue"
      ),
    name: "skillsNavigator",
    meta: {
      feature: Feature.SkillsExplorer,
      tracking: {
        inPage: true,
        sections: ["my careers", "skills"],
      },
    },
    beforeEnter: async (to, from, next) => {
      if (!store.getters.canAccessFeature(Feature.SkillsExplorer)) {
        return next({ name: "403" });
      }

      return next();
    },
    props: true,
  },
  {
    path: "/navigators/skillsets/skills",
    component: () =>
      import(
        /* webpackChunkName: "explorers" */ "@/modules/SkillsetsNavigator/pages/SkillsetsNavigatorSkillsListingPage/SkillNavigatorSkillsListingPage.vue"
      ),
    name: "skillSkillsNavigator",
    meta: {
      feature: Feature.SkillsExplorer,
      tracking: {
        inPage: true,
        sections: ["my careers", "skills"],
      },
    },
    beforeEnter: async (to, from, next) => {
      if (!store.getters.canAccessFeature(Feature.SkillsExplorer)) {
        return next({ name: "403" });
      }

      return next();
    },
    props: true,
  },
  {
    path: "/navigators/skillsets/skill-providers",
    component: () =>
      import(
        /* webpackChunkName: "explorers" */ "@/modules/SkillsetsNavigator/pages/SkillsetsNavigatorProvidersListingPage/SkillsetsNavigatorProvidersListingPage.vue"
      ),
    name: "skillsetsNavigatorSkillProviders",
    meta: {
      feature: Feature.SkillsExplorer,
      tracking: {
        inPage: true,
        sections: ["my careers", "skills-providers"],
      },
    },
    beforeEnter: async (to, from, next) => {
      if (!store.getters.canAccessFeature(Feature.SkillsExplorer)) {
        return next({ name: "403" });
      }

      return next();
    },
    props: true,
  },
  {
    path: "/navigators/skillsets/skills/:name/:id",
    component: () =>
      import(
        /* webpackChunkName: "explorers" */ "@/modules/SkillsetsNavigator/pages/SkillDetailPage"
      ),
    name: "skillDetailPage",
    meta: {
      feature: Feature.SkillsExplorer,
      tracking: {
        inPage: true,
        sections: ["my careers", "skills", "skill"],
      },
    },
    beforeEnter: async (to, from, next) => {
      if (!store.getters.canAccessFeature(Feature.SkillsExplorer)) {
        return next({ name: "403" });
      }

      return next();
    },

    props: true,
  },
  {
    path: "/navigators/skillsets/skill-providers/:name/:id",
    component: () =>
      import(
        /* webpackChunkName: "explorers" */ "@/modules/SkillsetsNavigator/pages/SkillProviderDetailPage"
      ),
    name: "skillsetsNavigatorSkillsProviderDetailPage",
    meta: {
      feature: Feature.SkillsExplorer,
      tracking: {
        inPage: true,
        sections: ["my careers", "skills", "skill providers"],
      },
    },
    beforeEnter: async (to, from, next) => {
      if (!store.getters.canAccessFeature(Feature.SkillsExplorer)) {
        return next({ name: "403" });
      }

      return next();
    },

    props: true,
  },
];
