import Vue from "vue";
import Vuetify from "vuetify/lib";
import config from "../config/vuetify.config";
import "@fortawesome/fontawesome-free/css/all.css"; // Ensure you are using css-loader

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        ...config.light,
        // Alerts
        accent: "#82B1FF",
        error: "#ff6067",
        info: "#FFDD18",
        success: "#209942",
        warning: "#FFC107",

        // Worker Types
        investigative: "#3B82F6",
        conventional: "#EF4444",
        realistic: "#EA580C",
        enterprising: "#8B5CF6",
        social: "#EC4899",
        artistic: "#16A34A",

        investigative_light: "#DBEAFE",
        conventional_light: "#FEE2E2",
        realistic_light: "#FFEDD5",
        enterprising_light: "#EDE9FE",
        social_light: "#FCE7F3",
        artistic_light: "#DCFCE7",

        data_01_primary: "#7C3AED",
        data_02_primary: "#2563EB",
        data_03_primary: "#059669",
        data_04_primary: "#65A30D",
        data_05_primary: "#F97316",

        rating_1: "#ff3100",
        rating_2: "#ff7900",
        rating_3: "#ffa700",
        rating_4: "#36c600",
        rating_5: "#00ad65",
      },
    },
  },
  icons: {
    iconfont: "fa",
  },
});
