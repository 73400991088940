<template>
  <v-toolbar-title class="app-logo">
    <router-link
      v-if="routerLinkName !== ''"
      :to="{ name: routerLinkName }"
      class="app-logo-link"
    >
      <img
        :alt="appConfiguration.appName"
        width="auto"
        :height="height"
        :src="img"
        :class="showIcon ? 'app-icon-image' : 'app-logo-image'"
      >
    </router-link>

    <img
      v-if="routerLinkName === ''"
      :alt="appConfiguration.appName"
      :height="height"
      :src="img"
      :class="showIcon ? 'app-icon-image' : 'app-logo-image'"
      width="100%"
    >
  </v-toolbar-title>
</template>

<script>
/**
 * Shows the application logo
 * @displayName App Logo
 */
export default {
  name: "AppLogo",
  props: {
    /**
     * Allows for switching between types of available logos
     */
    type: {
      type: String,
      default: ""
    },
    height: {
      type: Number,
      default: 39
    },
    /**
     * Set a link for the logo image (optional)
     */
    routerLinkName: {
      type: String,
      default: ""
    },
    showIcon: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    img() {
      if (this.type === "white")
        if (this.showIcon)
          return "/img/ci/app-icon-white.svg";
        else return "/img/ci/logo-white.svg";

      if (this.showIcon)
        return "/img/ci/app-icon.svg";
      else return "/img/ci/logo.svg";
    }
  }
};
</script>

<style scoped>
.app-logo {
  min-width: 50px;
}
.app-logo-image {
  width: auto;
  max-width: 100%;
  max-height: 39px;
}

.app-icon-image {
  width: auto;
  max-width: 100%;
  max-height: 27px;
  margin-top: 4px;
}
</style>
