
export default {
  cookieDomain: process.env.VUE_APP_COOKIE_DOMAIN || "localhost",
  appName: process.env.VUE_APP_NAME || "App Name",
  supportEmail: process.env.VUE_APP_SUPPORT_EMAIL,
  friendlyUrl: process.env.VUE_APP_FRIENDLY_URL,
  crispSupportChatId: process.env.VUE_APP_CRISP_CHAT_ID,
  // OneTrust Cookie Banner
  oneTrustDomainScriptId: process.env.VUE_APP_ONE_TRUST_SCRIPT_ID,
  // Type Form Embed ID
  typeFormEmbedId: process.env.VUE_APP_TYPE_FORM_EMBED_ID,
  // Analytics and Tracking
  trackDeskId: process.env.VUE_APP_TRACK_DESK_ID,
  adobeAnalyticsTracking: process.env.VUE_APP_ADOBE_ANALYTICS_LAUNCH_SCRIPT
};

