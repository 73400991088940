/**
 * Configuration File for PluggedIn
 */
import {Feature} from "@YenzaCT/sdk";

const appConfig = {
  auth: {
    providers: ["azure-ad", "stdbank"],
    stdbank: {
      singleSignOutUrl: `${process.env.VUE_APP_OAUTH_ISSUER_URL}/idp/startSLO.ping?AdapterId=DigialIDPAdapter&TargetResource=${process.env.VUE_APP_URL}`,
      oAuthEmailVerificationUrl: `${process.env.VUE_APP_OAUTH_ISSUER_URL}/pf/id/verification.ping?LocalIdentityProfileID=${process.env.VUE_APP_OAUTH_VERFICATION_PROFILE_ID}`,
    },
    "azure-ad": {
      singleSignOutUrl: `${process.env.VUE_APP_AZUREADB2C_ISSUER_URL}/${process.env.VUE_APP_AZUREADB2C_USER_FLOW}/oauth2/v2.0/logout?post_logout_redirect_uri=${process.env.VUE_APP_URL}`,
    },
    consents: [
      "terms",
      "marketing",
      "internalMarketingSharing",
      "researchContact",
      "parent"
    ],
    signupTypes: ["oauth"],
  },
  onBoarding: {
    initialProfile: [
      "birthDate",
      "firstName",
      "lastName",
      "idNumber",
      "email",
      "phone",
    ],
  },
  explorers: {
    skillsExplorer: {
      hidePaid: true,
    },
  },
  availableSyllabi: [
    "national_senior_certificate",
    "cambridge",
  ],
  legal: {
    termsOfUse: true,
    privacyPolicy: true,
    privacyInTerms: true,
  },
  assessmentEnglishLocale: "en_ZA",
  platformLanguages: [
    "en"
  ],
  languages: [
    "en",
    "af",
    "xh",
    "zu",
  ],
  otherLanguages: [],
  features: [
    // Reports
    Feature.WorkerTypeReport,
    Feature.EntrepreneurReport,
    Feature.PersonalityTraitsReport,
    Feature.AreasOfDevelopmentReport,
    Feature.LearningStylesReport,
    Feature.CognitiveAbilityReport,
    Feature.EmotionalIntelligenceReport,
    // Explorers
    Feature.CareerExplorer,
    Feature.SubjectChoiceExplorer,
    Feature.TertiaryInstitutionExplorer,
    Feature.BursariesExplorer,
    Feature.ResourcesExplorer,
    Feature.SkillsExplorer,
    Feature.FundingExplorer,
    Feature.ReportsExplorer,
    Feature.UpliftmentProviderExplorer,
    // Tools
    Feature.CourseDiscounts,
    Feature.Dashboard,
    Feature.AssessmentDashboard,
    Feature.Profile,
    Feature.Articles,
    Feature.CareerMatches,
    Feature.Cv,
    "action_hub",
    "opportunity_hub",
    "funding_explorer"
  ],
  comingSoonFeatures: [
    Feature.UpliftmentProviderExplorer,
    Feature.BursariesExplorer,
    Feature.ValueScalesReport
  ]
};

export default appConfig;
