import { Feature } from "@YenzaCT/sdk";
import store from "@/store";

export default [
  /** ====================================================================================================
     * TERTIARY EXPLORER
     ====================================================================================================*/
  {
    path: "/navigators/tertiary-institutions",
    component: () =>
      import(
        /* webpackChunkName: "tertiaryNavigator" */ "@/modules/TertiaryNavigator/pages/TertiaryNavigatorListingPage/TertiaryNavigatorListingPage.vue"
      ),
    name: "tertiaryNavigator",
    meta: {
      feature: Feature.TertiaryInstitutionExplorer,
      tracking: {
        inPage: true,
        sections: ["navigators", "tertiary-institutions"],
      },
    },
    beforeEnter: async (to, from, next) => {
      if (
        !store.getters.canAccessFeature(Feature.TertiaryInstitutionExplorer)
      ) {
        return next({ name: "403" });
      }

      return next();
    },
    props: true,
  },
  {
    path: "/navigators/tertiary-institutions/explore",
    component: () =>
      import(
        /* webpackChunkName: "tertiaryNavigator" */ "@/modules/TertiaryNavigator/pages/TertiaryExplorePage/TertiaryExplorePage.vue"
      ),
    name: "tertiaryExplorePage",
    meta: {
      feature: Feature.TertiaryInstitutionExplorer,
      tracking: {
        inPage: true,
        sections: ["navigators", "tertiary-institutions"],
      },
    },
    beforeEnter: async (to, from, next) => {
      if (
        !store.getters.canAccessFeature(Feature.TertiaryInstitutionExplorer)
      ) {
        return next({ name: "403" });
      }

      return next();
    },
    props: true,
  },
  {
    path: "/navigators/tertiary-institutions/explore/:name/:id",
    component: () =>
      import(
        /* webpackChunkName: "explorers" */ "@/modules/TertiaryNavigator/pages/CareerClusterPage.vue"
      ),
    name: "tertiaryNavigatorClusterDetailPage",
    meta: {
      feature: Feature.TertiaryInstitutionExplorer,
      tracking: {
        inPage: true,
        sections: [
          "my careers",
          "tertiary institutions",
          "career cluster detail",
        ],
      },
    },
    beforeEnter: async (to, from, next) => {
      if (
        !store.getters.canAccessFeature(Feature.TertiaryInstitutionExplorer)
      ) {
        return next({ name: "403" });
      }

      return next();
    },

    props: true,
  },
  {
    path: "/navigators/tertiary-institutions/institution/:name/:id",
    component: () =>
      import(
        /* webpackChunkName: "tertiaryNavigator" */ "@/modules/TertiaryNavigator/pages/TertiaryInstitutionDetailPage.vue"
      ),
    name: "tertiaryDetailPage",
    meta: {
      feature: Feature.BursariesExplorer,
      tracking: {
        inPage: true,
        sections: ["navigators", "bursaries", "bursary"],
      },
    },
    beforeEnter: async (to, from, next) => {
      if (
        !store.getters.canAccessFeature(Feature.TertiaryInstitutionExplorer)
      ) {
        return next({ name: "403" });
      }

      return next();
    },
    props: true,
  },
];
