import analytics from "@/library/analytics.lib";
import { ASSESSMENT_LABELS, EXPLORER_LABELS } from "@/library/constants";
import { cloneDeep } from "lodash";

/**
 * Tracks assessment instruction closure
 * @param assessmentId The ID of the assessment
 */
function closedAssessmentInstruction(assessmentId) {
  analytics.sendEvent(
    "Assessments: Closed Instruction",
    {
      assessment: ASSESSMENT_LABELS[assessmentId]
    },
    "assessments",
    "close_instruction"
  );
}

/**
 * Tracks assessment instruction opening
 * @param assessmentId The ID of the assessment
 */
function openedAssessmentInstruction(assessmentId) {
  analytics.sendEvent(
    "Assessments: Opened Instruction",
    {
      assessment: ASSESSMENT_LABELS[assessmentId]
    },
    "assessments",
    "open_instruction"
  );
}

/**
 * Tracks when the user starts an assessment
 * @param assessmentId The ID of the assessment
 */
function startedAssessment(assessmentId) {
  analytics.sendEvent(
    "Assessments: Started",
    {
      assessment: ASSESSMENT_LABELS[assessmentId]
    },
    "assessments",
    "start_assessment"
  );
  analytics.timeEvent("Assessments: Finished");
}

/**
 * Tracks when the user finishes an assessment
 * @param assessmentId The ID of the assessment
 */
function finishedAssessment(assessmentId) {
  analytics.sendEvent(
    "Assessments: Finished",
    {
      assessment: ASSESSMENT_LABELS[assessmentId]
    },
    "assessments",
    "finish_assessment"
  );
}

/**
 * Tracks when the user changes language during an assessment
 * @param assessmentId The ID of the assessment
 * @param fromLanguage The language the assessment is currently in
 * @param toLanguage The language they are changing the assessment to
 */
function changedAssessmentLanguage(assessmentId, fromLanguage, toLanguage) {
  analytics.sendEvent(
    "Assessments: Changed Language",
    {
      assessment: ASSESSMENT_LABELS[assessmentId],
      fromLanguage,
      toLanguage
    },
    "assessments",
    "change_assessment_language"
  );
}

/**
 * Tracks when a user visits a page
 * @param pageName The name of the page the user visited
 */
function visitsPage(pageName) {
  analytics.sendEvent(
    "Visits Page",
    {
      pageName
    },
    "pages",
    "visit_page"
  );
}

function clickedLink(intent, name, scope = "cta") {
  analytics.sendEvent(
    "Clicked Link",
    {
      intent,
      name,
      scope
    },
    "links",
    "clicked_link",
    "linkClick"
  );
}

function formStart(name) {
  analytics.sendEvent(
    "Form Started",
    {
      name
    },
    "forms",
    "form_started",
    "formStart"
  );
}

function formComplete(name) {
  analytics.sendEvent(
    "Form Completed",
    {
      name
    },
    "forms",
    "form_completed",
    "formComplete"
  );
}

/**
 * Records a page view
 * @param route The route object for the page to track
 * @param subSections The subsections to add to the tracking
 */
function pageView(route, subSections) {
  let sections = route.meta.tracking.sections ? cloneDeep(route.meta.tracking.sections) : [];
  const routeName = route.name;
  const params = route.params;
  const externalPage = route.meta.isLoggedOut ? route.meta.isLoggedOut : null;

  // Add the final pageName to the sections list (if it exists)
  if (subSections) sections = sections.concat(subSections);

  // Join the sections together with : for the full page name
  const pageName = sections.join(":");

  // Convert the sections into their subsections
  const pageSubSections = {
    pageSubSection1: "",
    pageSubSection2: "",
    pageSubSection3: "",
    pageSubSection4: "",
  };
  let pageSections = "";

  for (let i = 0; i < sections.length; i++) {
    if (i < sections.length && i > 0) pageSections += ":";

    pageSections += sections[i];

    pageSubSections[`pageSubSection${i + 1}`] = pageSections;
  }

  analytics.sendEvent(
    "Opened Page",
    {
      pageName,
      routeName,
      params,
      externalPage,
      pageSubSections
    },
    "pages",
    "open_page",
    "pageView"
  );

  // Starts a time event that tracks time between "Opened Page" and "Exited Page" events
  analytics.timeEvent("Exited Page");
}

function openedPage(pageName, routeName, params, externalPage = false) {
  analytics.sendEvent(
    "Opened Page",
    {
      pageName,
      routeName,
      params,
      externalPage
    },
    "pages",
    "open_page",
    "pageView"
  );
  analytics.timeEvent("Exited Page");
}

function exitedPage(pageName, externalPage = false) {
  analytics.sendEvent(
    "Exited Page",
    {
      pageName,
      externalPage
    },
    "pages",
    "exit_page"
  );
}

/**
 * Tracks when a user opens the app
 * @param pageName The name of the page the user visited
 */
function openedApp() {
  analytics.sendEvent("Opened App", {}, "app", "open_app", "openApp");
}

/**
 * Tracks when a user attempts to check an access codes validity
 * @param accessCode The access code the user is checking
 * @param wasCodeFound Does the code exist
 * @param isCodeValid Is the code valid
 * @param isCodeUsed Is the code already in use
 */
function checkAccessCodeValidity(accessCode, wasCodeFound, isCodeValid, isCodeUsed) {
  analytics.sendEvent(
    "Access Code: Check Validity",
    {
      accessCode,
      wasCodeFound: wasCodeFound ? "Not Found" : "Found",
      isCodeValid: isCodeValid ? "Valid" : "Invalid",
      isCodeUsed: isCodeUsed ? "In Use" : "Not Used"
    },
    "onboarding",
    "check_access_code"
  );
}

function redeemAccessCode(accessCode) {
  analytics.sendEvent(
    "Access Code: Redeemed",
    {
      accessCode
    },
    "onboarding",
    "redeem_access_code"
  );
}

/**
 * Tracks when the user has created a new account
 * @param accessCode
 * @param signupMethod
 */
function createdAccount(accessCode, signupMethod, accountType, coupon) {
  analytics.sendEvent(
    "Onboarding: Account Created",
    {
      accessCode,
      signupMethod,
      accountType,
      coupon
    },
    "onboarding",
    "create_account"
  );
}

/**
 * Tracks when a user logs into the app
 * @param authenticationMethod The method they used to authenticate, eg email, snappilify
 */
function authenticatedUser(authenticationMethod) {
  analytics.sendEvent("Authenticate", { authenticationMethod }, "authentication", "login");
}

function openedAuthProvider(provider) {
  analytics.sendEvent(
    "oAuth: Opened",
    {
      provider
    },
    "consent",
    "oauth_opened"
  );
}
function authProviderCallbackSuccessLoaded() {
  analytics.sendEvent("oAuth: Success", {}, "consent", "oauth_success", "oauthSuccess");
}
function authProviderCallbackErrorLoaded() {
  analytics.sendEvent("oAuth: Error", {}, "consent", "oauth_error");
}

/** **************************************************************************
 * EXPLORERS
 ****************************************************************************/

/**
 * Tracks when the user opens (or returns to) an explorer
 * @param explorerId
 */
function openedExplorer(explorerId) {
  analytics.sendEvent(
    "Explorers: Opened",
    { explorer: EXPLORER_LABELS[explorerId] },
    "explorers",
    "open_explorer"
  );
}

function changedExplorerSubSection(explorerId, subSection) {
  analytics.sendEvent(
    "Explorers: Subsection Opened",
    {
      explorer: EXPLORER_LABELS[explorerId],
      subSection
    },
    "explorers",
    "open_subsection"
  );
}

function openedExplorerContentItem(explorerId, content, contentItem) {
  analytics.sendEvent(
    "Explorers: Opened Content Item",
    {
      explorer: EXPLORER_LABELS[explorerId],
      content,
      contentItem
    },
    "explorers",
    "open_content_item"
  );
}

function addedCareerToFavourites(career) {
  analytics.sendEvent(
    "Careers: Added Career Favourites",
    {
      career
    },
    "careers",
    "add_career_to_favourites"
  );
}

function openedExplorerFilter(explorerId, filter, content) {
  analytics.sendEvent(
    "Explorers: Opened Filter",
    {
      explorer: EXPLORER_LABELS[explorerId],
      filter,
      content
    },
    "explorers",
    "filter_explorer"
  );
}

function loadedAdditionalExplorerContent(explorerId, content) {
  analytics.sendEvent(
    "Explorers: Loaded More Content Items",
    {
      explorer: EXPLORER_LABELS[explorerId],
      content
    },
    "explorers",
    "load_additional_content_items"
  );
}

function openedExplorerHelp(explorerId) {
  analytics.sendEvent(
    "Explorers: Opened Help",
    {
      explorer: EXPLORER_LABELS[explorerId]
    },
    "explorers",
    "open_help"
  );
}

function visitedExplorerExternalWebsite(explorerId, content, provider, contentItem, link) {
  analytics.sendEvent(
    "Explorers: Open External Link",
    {
      explorer: EXPLORER_LABELS[explorerId],
      content,
      provider,
      contentItem,
      link
    },
    "explorers",
    "open_external_link"
  );
}

function appliedExplorerFilter(explorerId, filter, content, value) {
  analytics.sendEvent(
    "Explorers: Applied Filter",
    {
      explorer: EXPLORER_LABELS[explorerId],
      filter,
      content,
      value
    },
    "explorers",
    "apply_filter"
  );
}

function changedExplorerWorkerType(explorerId, workerType) {
  analytics.sendEvent(
    "Explorers: Changed Explorer Worker Type",
    {
      explorer: EXPLORER_LABELS[explorerId],
      workerType
    },
    "explorers",
    "change_worker_type"
  );
}

function openedExplorerCareerCluster(explorerId, cluster) {
  analytics.sendEvent(
    "Explorers: Opened Career Cluster",
    {
      explorer: EXPLORER_LABELS[explorerId],
      cluster
    },
    "explorers",
    "open_career_cluster"
  );
}

function selectedSchoolSubject(subjectType, subjectId) {
  analytics.sendEvent(
    "Explorers: Selected School Subject",
    {
      subjectType,
      subjectId
    },
    "subjects",
    "select_school_subject"
  );
}

function selectedAllSchoolSubjects() {
  analytics.sendEvent(
    "Explorers: Selected All School Subjects",
    {},
    "subjects",
    "select_all_school_subjects"
  );
}

function viewedSubjectDefinition(subjectTitle) {
  analytics.sendEvent(
    "Explorers: Viewed School Subject Definition",
    {
      subjectTitle
    },
    "subjects",
    "view_subject_definition"
  );
}

function openedSkillExplorerProviderBenefit(title, provider) {
  analytics.sendEvent(
    "Explorers: Opened Skill Provider Benefit",
    {
      title,
      provider
    },
    "explorers",
    "open_skill_provider_benefit"
  );
}

/** **************************************************************************
 * REPORTS
 ****************************************************************************/

function downloadedAssessmentReport() {
  analytics.sendEvent("Reports: Download PDF Report", {}, "reports", "download_report");
}

function expandedReportInformation(report, section) {
  analytics.sendEvent(
    "Reports: Expanded Report Information",
    {
      report,
      section
    },
    "reports",
    "expand_report_information"
  );
}

function openedCareerInReport(career) {
  analytics.sendEvent(
    "Reports: Opened Career",
    {
      career
    },
    "reports",
    "open_career"
  );
}

/** **************************************************************************
 * CV
 ****************************************************************************/

function openedCvEditSection(section) {
  analytics.sendEvent(
    "CV: Opened Section",
    {
      section
    },
    "cv",
    "open_section"
  );
}

function updatedCv(section, completedSections, incompleteSections, percentageComplete) {
  analytics.sendEvent(
    "CV: Updated Section",
    {
      section,
      completedSections,
      incompleteSections,
      percentageComplete
    },
    "cv",
    "update_section"
  );
}

function openedCvSectionHelpDialog(section) {
  analytics.sendEvent(
    "CV: Opened Help",
    {
      section
    },
    "cv",
    "open_help"
  );
}

function addedCustomCvRecord(section, recordValue) {
  analytics.sendEvent(
    "CV: Added Custom Record",
    {
      section,
      recordValue
    },
    "cv",
    "add_custom_record"
  );
}

function addedCvReference(section) {
  analytics.sendEvent(
    "CV: Added Reference",
    {
      section
    },
    "cv",
    "add_cv_reference"
  );
}

function openedPageHelp(section) {
  analytics.sendEvent(
    "Opened Help",
    {
      section
    },
    "cv",
    "open_help"
  );
}

function changedCVSection(section) {
  analytics.sendEvent(
    "CV: Changed Tab",
    {
      section
    },
    "cv",
    "change_cv_tab"
  );
}

/** **************************************************************************
 * DASHBOARD
 ****************************************************************************/

function clickedDashboardBadge(section) {
  analytics.sendEvent(
    "Dashboard: Navigated to Section",
    {
      section
    },
    "dashboard",
    "click_dashboard_badge"
  );
}

/** **************************************************************************
 * ONBOARDING
 ****************************************************************************/
function startedOnboarding(type) {
  analytics.sendEvent(
    "Onboarding: Started",
    {
      type
    },
    "onboarding",
    "started_onboarding"
  );
}

function movedToNextOnboardingStep(fromStep, step, flow) {
  analytics.sendEvent(
    "Onboarding: Moved to Next Step",
    {
      fromStep,
      step,
      flow
    },
    "onboarding",
    "navigate_to_next_onboarding_step"
  );
}

function movedToPreviousOnboardingStep(fromStep, step, flow) {
  analytics.sendEvent(
    "Onboarding: Moved to Previous Step",
    {
      fromStep,
      step,
      flow
    },
    "onboarding",
    "navigate_to_previous_onboarding_step"
  );
}

function completedOnboarding(flow) {
  analytics.sendEvent(
    "Onboarding: Completed",
    {
      flow
    },
    "onboarding",
    "completed_onboarding"
  );
}

function failedOnboarding(flow, reason) {
  analytics.sendEvent(
    "Onboarding: Failed",
    {
      flow,
      reason
    },
    "onboarding",
    "failed_onboarding"
  );
}

function submittedConsent() {
  analytics.sendEvent("Consent: Submitted", {}, "consent", "consent_submitted");
}

function openedConsent() {
  analytics.sendEvent("Consent: Opened", {}, "consent", "consent_opened");
}

export default {
  clickedLink,
  formStart,
  formComplete,
  closedAssessmentInstruction,
  openedAssessmentInstruction,
  startedAssessment,
  finishedAssessment,
  changedAssessmentLanguage,
  visitsPage,
  openedPage,
  pageView,
  exitedPage,
  openedApp,
  checkAccessCodeValidity,
  redeemAccessCode,
  createdAccount,
  authenticatedUser,
  openedExplorer,
  openedExplorerFilter,
  appliedExplorerFilter,
  openedExplorerContentItem,
  visitedExplorerExternalWebsite,
  loadedAdditionalExplorerContent,
  openedExplorerHelp,
  changedExplorerWorkerType,
  openedExplorerCareerCluster,
  addedCareerToFavourites,
  selectedSchoolSubject,
  viewedSubjectDefinition,
  openedSkillExplorerProviderBenefit,
  changedExplorerSubSection,
  downloadedAssessmentReport,
  expandedReportInformation,
  openedCareerInReport,
  openedCvEditSection,
  updatedCv,
  openedCvSectionHelpDialog,
  addedCustomCvRecord,
  addedCvReference,
  openedPageHelp,
  changedCVSection,
  clickedDashboardBadge,
  selectedAllSchoolSubjects,
  movedToNextOnboardingStep,
  startedOnboarding,
  completedOnboarding,
  failedOnboarding,
  openedAuthProvider,
  authProviderCallbackErrorLoaded,
  authProviderCallbackSuccessLoaded,
  submittedConsent,
  openedConsent,
  movedToPreviousOnboardingStep
};
